<div class="external-layout">
  <app-logo-link
    [fillLogo]="isAdaptiveLogo() ? '#6F9305' : '#ffffff'"
    [fillText]="isAdaptiveLogo() ? '#050609' : '#ffffff'"
    class="logo"
  />

  <div class="side-block">
    <img src="assets/auth-img.png" alt="Закрома" class="img" />
  </div>

  <ng-content />

  <div class="side-block"></div>
</div>
