import { Component, computed, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { AuthService } from '@services/auth.service';
import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_CONTACTS,
  ROUTE_PRICE_ANALYSIS,
  ROUTE_PROFILE,
} from '@settings/routes';

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [MatIconModule, MatMenuModule, MatButtonModule, RouterLink, NgClass],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
})
export class MobileMenuComponent {
  constructor(private authService: AuthService) {}

  isAuth = this.authService.isAuth;

  profileOrAuthLink = computed(() =>
    this.isAuth() ? ROUTE_PROFILE : ROUTE_AUTH,
  );
  announcementCreateLink = ROUTE_ANNOUNCEMENT_CREATE;
  analysisLink = ROUTE_PRICE_ANALYSIS;
  contactsLink = ROUTE_CONTACTS;

  logOut = output();
  logOutFunction() {
    this.logOut.emit();
  }
}
