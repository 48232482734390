<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>

<div class="dialog-title">По умолчанию</div>

<mat-dialog-content class="dialog-content">
  Вы уверены, что хотите привести ваш личный приоритет отображения объявлений к
  параметру «По умолчанию»?
</mat-dialog-content>

<mat-dialog-actions class="dialog-footer">
  <app-button variety="secondary" [mat-dialog-close]="true"
    >Сделать «По умолчанию»</app-button
  >
  <app-button mat-dialog-close>Назад</app-button>
</mat-dialog-actions>
