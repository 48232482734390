<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>

<div class="dialog-title">
  Покупка пакета на
  {{ data.contactPackage.openings_count }}
  {{
    data.contactPackage.openings_count
      | declense: ["открытие", "открытия", "открытий"]
  }}
  контактов
</div>

<mat-dialog-content class="dialog-content">
  <span
    >Стоимость:
    {{ data.contactPackage.price | number: "1.0" : "ru-Ru" }} руб</span
  ><br /><br />

  Выберите карту из ваших привязанных или добавьте новую:

  <div class="cards">
    <button class="button-card-data">
      <div class="info-card-block">
        <img src="assets/icons/t-bank.png" alt="" class="bank-logo" />
        <div>Tinkoff Pay</div>
      </div>
      <div>546752******8765</div>
    </button>

    <button class="button-card-data selected">
      <svg
        class="selected-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect width="20" height="20" rx="10" fill="#629EE4" />
        <path
          d="M13.832 7.5L8.33203 12.8333L5.83203 10.4091"
          stroke="white"
          stroke-width="2.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="info-card-block">
        <img src="assets/icons/sber.png" alt="" class="bank-logo" />
        <div>SberPay</div>
      </div>
      <div>546752******8765</div>
    </button>

    <button type="button" class="button-card-add">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M3.5 10H21.5M7.5 15H7.51M11.5 15H13.5M3.5 8C3.5 7.20435 3.81607 6.44129 4.37868 5.87868C4.94129 5.31607 5.70435 5 6.5 5H18.5C19.2956 5 20.0587 5.31607 20.6213 5.87868C21.1839 6.44129 21.5 7.20435 21.5 8V16C21.5 16.7956 21.1839 17.5587 20.6213 18.1213C20.0587 18.6839 19.2956 19 18.5 19H6.5C5.70435 19 4.94129 18.6839 4.37868 18.1213C3.81607 17.5587 3.5 16.7956 3.5 16V8Z"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div>Другая карта</div>
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-footer">
  <app-button variety="secondary" [mat-dialog-close]="true"
    >Купить пакет</app-button
  >
  <app-button variety="transparent" mat-dialog-close>Назад</app-button>
</mat-dialog-actions>
