import { TMenuItemsString } from '@models/commonTypes';
import {
  ROUTE_PROFILE,
  ROUTE_PROFILE_CONTACTS,
  ROUTE_PROFILE_PRIORITY,
  ROUTE_PROFILE_SAFETY,
  ROUTE_SETTINGS,
} from '@settings/routes';

export const settingsProfileMenuList: TMenuItemsString = [
  {
    id: 'settings-profile',
    link: `/${ROUTE_PROFILE}/${ROUTE_SETTINGS}`,
    name: 'Личные данные',
  },
  {
    id: 'settings-profile-contacts',
    link: ROUTE_PROFILE_CONTACTS,
    name: 'Контактные данные',
  },
  {
    id: 'settings-profile-safety',
    link: ROUTE_PROFILE_SAFETY,
    name: 'Безопасность',
  },
  {
    id: 'settings-profile-priority',
    link: ROUTE_PROFILE_PRIORITY,
    name: 'Приоритет отображения объявлений',
  },
];
