<div class="payment-subtitle">Привязанные банковские карты</div>

<div class="payment-subscription">
  <div class="button-card-data-wrapper">
    <button type="button" class="button-card-data">
      <mat-icon class="close" (click)="openDeleteCardDialog()">close</mat-icon>
      <div class="info-card-block">
        <img src="assets/icons/t-bank.png" alt="" class="bank-logo" />
        <div>Tinkoff Pay</div>
      </div>
      <div>546752******8765</div>
    </button>

    <button type="button" class="button-card-data selected">
      <svg
        class="selected-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect width="20" height="20" rx="10" fill="#629EE4" />
        <path
          d="M13.832 7.5L8.33203 12.8333L5.83203 10.4091"
          stroke="white"
          stroke-width="2.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <mat-icon class="close" (click)="openDeleteCardDialog()">close</mat-icon>

      <div class="info-card-block">
        <img src="assets/icons/sber.png" alt="" class="bank-logo" />
        <div>SberPay</div>
      </div>
      <div>546752******8765</div>
    </button>
  </div>

  <app-button-add-card />
</div>
