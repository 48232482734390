import { Component, computed, effect, inject, signal } from '@angular/core';
import { NgIf } from '@angular/common';

import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { PriorityService } from './core/priority.service';
import { SearchComponent } from '@components/ui/search/search.component';
import { TAnnouncementType } from '@models/announcementTypes';
import { TToogleCategoriesTreeEntity } from './core/priorityTypes';
import { ResettingPrioritiesDialogComponent } from '@components/common/dialog/resetting-priorities-dialog/resetting-priorities-dialog.component';
import { TFormattedApiError } from '@utils/formattingApiError';

import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-priority',
  standalone: true,
  imports: [
    InfoBlockComponent,
    ButtonComponent,
    MatFormField,
    MatIcon,
    MatCheckbox,
    MatExpansionModule,
    SearchComponent,
    NgIf,
  ],
  templateUrl: './priority.component.html',
  styleUrl: './priority.component.scss',
})
export class PriorityComponent {
  priorityService = inject(PriorityService);
  readonly dialog = inject(MatDialog);
  notification = inject(NotificationService);

  categoriesType = signal<TAnnouncementType>('sell');
  categoryList = this.priorityService.listSelectedCategories;

  regionsList = this.priorityService.listSelectedRegions;
  loading = this.priorityService.loading;
  defaultLoading = this.priorityService.defaultLoading;

  regionLoading = signal(false);
  categoryLoading = signal(false);

  filterRegionsList = signal(this.regionsList());

  // Для сообщения о выбранных регионах
  numberSelectedRegions = computed(() => {
    return this.regionsList().filter((region) => region.is_selected).length;
  });

  firstSelectedRegion = computed(() => {
    return this.regionsList().find((region) => region.is_selected);
  });

  messageSelectedRegions = computed(() => {
    if (this.numberSelectedRegions() === 0) return 'Нет выбранных';

    if (this.numberSelectedRegions() === this.regionsList().length)
      return 'Все';

    if (this.numberSelectedRegions() === 1 && this.firstSelectedRegion())
      return this.firstSelectedRegion()?.name;

    return `${this.firstSelectedRegion()?.name} +${this.numberSelectedRegions() - 1}`;
  });

  allRegions = signal<boolean>(false);

  // Обновление списка регионов при получении нового списка с бэка
  changeRegions = effect(
    () => {
      this.filterRegionsList.set(this.regionsList());
      this.allRegions.set(
        this.numberSelectedRegions() === this.regionsList().length,
      );
    },
    { allowSignalWrites: true },
  );

  changeCategoriesType = effect(
    () => {
      // todo добавить дизейбл категорий и кнопки
      this.priorityService.loadListSelectedCategories({
        type: this.categoriesType(),
      });
    },
    { allowSignalWrites: true },
  );

  // Снятие с публикации через модальное окно
  openDefaultDialog() {
    const dialogRef = this.dialog.open(ResettingPrioritiesDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.priorityService.setDefaults(this.categoriesType());
      }
    });
  }

  // Поиск в списке регионов
  onRegionSearch(search: string) {
    if (search) {
      this.filterRegionsList.set(
        this.regionsList()?.filter((region) =>
          region.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        ) || [],
      );
    } else {
      this.filterRegionsList.set(this.regionsList() || []);
    }
  }

  changingTypeCategories(newValue: TAnnouncementType) {
    this.categoriesType.set(newValue);
  }

  toogleAllRegions() {
    this.allRegions.update((prev) => !prev);

    if (this.allRegions()) this.priorityService.selectAllRegions();
    else this.priorityService.resetAllRegions();

    this.regionLoading.set(true);

    this.priorityService
      .setListSelectedRegions(this.priorityService.createRegionsRequestData())
      .subscribe({
        next: () => {
          this.regionLoading.set(false);
        },
        error: (error: TFormattedApiError) => {
          this.regionLoading.set(false);

          if (error.formattedErrorMessage)
            this.notification.error(error.formattedErrorMessage);
        },
      });
  }

  toogleRegion(id: number, newValue: boolean) {
    this.priorityService.toogleRegion(id, newValue);

    this.regionLoading.set(true);

    this.priorityService
      .setListSelectedRegions(this.priorityService.createRegionsRequestData())
      .subscribe({
        next: () => {
          this.regionLoading.set(false);
        },
        error: (error: TFormattedApiError) => {
          this.regionLoading.set(false);

          if (error.formattedErrorMessage)
            this.notification.error(error.formattedErrorMessage);
        },
      });
  }

  toogleCategoriesTreeEntity(event: Event, ids: TToogleCategoriesTreeEntity) {
    event.stopPropagation();
    event.preventDefault();

    this.priorityService.toogleCategoriesTreeEntity(ids);

    this.categoryLoading.set(true);

    this.priorityService
      .setListSelectedCategories(
        this.categoriesType(),
        this.priorityService.createCategoriesRequestData(),
      )
      .subscribe({
        next: () => {
          this.categoryLoading.set(false);
        },
        error: (error: TFormattedApiError) => {
          this.categoryLoading.set(false);

          if (error.formattedErrorMessage)
            this.notification.error(error.formattedErrorMessage);
        },
      });
  }
}
