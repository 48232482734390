import { Component } from '@angular/core';

@Component({
  selector: 'app-open-contacts',
  standalone: true,
  imports: [],
  templateUrl: './open-contacts.component.html',
  styleUrl: './open-contacts.component.scss',
})
export class OpenContactsComponent {}
