<app-external-layout>
  @if (!loadingStep()) {
    <div class="full-screen-form">
      @switch (currentStep()) {
        @case ("set-phone-number") {
          <app-recovery-set-phone />
        }

        @case ("confirm-phone-number") {
          <app-recovery-confirm-phone />
        }

        @case ("set-password") {
          <app-recovery-set-password />
        }

        @case ("password-changed") {
          <app-recovery-password-changed />
        }

        @default {
          <app-info-block
            variety="error"
            [description]="
              errorCurrentStep()?.formattedErrorMessage || 'Неизвестная ошибка'
            "
          />
        }
      }

      @if (currentStep() !== "password-changed") {
        <div class="link-block">
          Нет аккаунта?
          <app-link
            [link]="registrationRoute"
            description="Зарегистрироваться"
          />
        </div>
      }
    </div>
  } @else {
    <app-loader class="password-recovery-loader" />
  }
</app-external-layout>
