import {
  ROUTE_FAVOURITES,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_OPEN_CONTACTS,
  ROUTE_PROFILE,
  ROUTE_SETTINGS,
  ROUTE_SUBSCRIPTION,
} from '@settings/routes';

export const headerProfileMenuList = [
  {
    id: 'header-profile-menu-1',
    link: `${ROUTE_PROFILE}/${ROUTE_MY_ANNOUNCEMENTS}`,
    label: 'Мои объявления',
  },
  {
    id: 'header-profile-menu-2',
    link: `${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`,
    label: 'Избранное',
  },
  {
    id: 'header-profile-menu-3',
    link: `${ROUTE_PROFILE}/${ROUTE_OPEN_CONTACTS}`,
    label: 'Открытые контакты',
  },
  {
    id: 'header-profile-menu-4',
    link: `${ROUTE_PROFILE}/${ROUTE_SUBSCRIPTION}`,
    label: 'Подписка',
  },
  {
    id: 'header-profile-menu-5',
    link: `${ROUTE_PROFILE}/${ROUTE_SETTINGS}`,
    label: 'Настройки',
  },
];
