import { Component, computed } from '@angular/core';
import { RouterLink } from '@angular/router';

import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_FAVOURITES,
} from '@settings/routes';
import { footerMenuList, footerMenuListOpen } from '@settings/footerMenuLists';
import { SearchComponent } from '@components/ui/search/search.component';
import { MenuLinkComponent } from '@components/ui/menu-link/menu-link.component';
import { IconButtonComponent } from '@components/ui/icon-button/icon-button.component';
import { ContactBlockComponent } from './contact-block/contact-block.component';
import { IconButtonLinkComponent } from '@components/ui/icon-button-link/icon-button-link.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { LogoLinkComponent } from '../logo-link/logo-link.component';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink,
    MenuLinkComponent,
    ButtonLinkComponent,
    IconButtonComponent,
    IconButtonLinkComponent,
    SearchComponent,
    ContactBlockComponent,
    LogoLinkComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(private authService: AuthService) {}

  isAuth = this.authService.isAuth;
  menuList = computed(() =>
    this.isAuth() ? footerMenuList : footerMenuListOpen,
  );

  year = new Date().getFullYear();

  authLink = ROUTE_AUTH;
  favouritesLink = ROUTE_FAVOURITES;
  announcementCreateLink = ROUTE_ANNOUNCEMENT_CREATE;

  // Контактные данные
  emails = ['example@gmail.com'];
  phones = ['8 800 700 9900', '+7 (913) 333 44 55'];

  scrollToTop(): void {
    document.getElementById('root')?.scrollIntoView();
  }
}
