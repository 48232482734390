import { Component, DestroyRef, afterNextRender, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  throttleTime,
} from 'rxjs';

import { MyAnnouncementsCardComponent } from '../../my-announcements-card/my-announcements-card.component';
import { DataWrapperComponent } from '@components/common/data-wrapper/data-wrapper.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';

import { MyAnnouncementsService } from '../../core/myAnnouncements.service';

@Component({
  selector: 'app-my-announcements-draft',
  standalone: true,
  imports: [
    MyAnnouncementsCardComponent,
    DataWrapperComponent,
    LoaderGlobalComponent,
    InfoBlockComponent,
  ],
  templateUrl: './my-announcements-draft.component.html',
  styleUrl: './my-announcements-draft.component.scss',
})
export class MyAnnouncementsDraftComponent {
  myAnnouncements = this.myAnnouncementsService.myAnnouncementsList;

  isLoading = this.myAnnouncementsService.loader;
  isInfiniteLoading = this.myAnnouncementsService.infiniteLoader;
  error = this.myAnnouncementsService.error;

  destroyRef = inject(DestroyRef);

  constructor(private myAnnouncementsService: MyAnnouncementsService) {
    afterNextRender(() => {
      this.myAnnouncementsService.resetMyAnnouncements();
      this.myAnnouncementsService.loadMyAnnouncements({
        params: { status_value: 'draft' },
      });
      this.myAnnouncementsService.loadMyAnnouncementsStatusesCount();

      // Высота footer или мобильного footer + половина высоты карточки объявления
      const footerHeight =
        document.getElementById('footer')?.clientHeight || 70;

      const pageByScroll$ = fromEvent(window, 'scroll', { passive: true }).pipe(
        map(() => window.scrollY),
        filter(
          (scrollY) =>
            scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footerHeight -
              210,
        ),
        throttleTime(200),
        distinctUntilChanged(),
      );

      pageByScroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        if (!this.isLoading() && !this.isInfiniteLoading())
          this.myAnnouncementsService.loadMyAnnouncements({
            params: {
              status_value: 'draft',
            },
            isInfinite: true,
          });
      });
    });
  }
}
