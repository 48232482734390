import { Component, input } from '@angular/core';

import { MenuLinkComponent } from '@components/ui/menu-link/menu-link.component';

@Component({
  selector: 'app-contact-block',
  standalone: true,
  imports: [MenuLinkComponent],
  templateUrl: './contact-block.component.html',
  styleUrl: './contact-block.component.scss',
})
export class ContactBlockComponent {
  variety = input<'email' | 'phone'>('email');
  contacts = input.required<string[]>();
}
