<div class="contact-block">
  <div class="label">
    @if (variety() === "email") {
      Email
    } @else {
      Телефоны для связи
    }
  </div>

  <div class="contact-links-block">
    @if (variety() === "email") {
      <img src="assets/icons/email.svg" alt="Email" class="footer-icon" />
    } @else {
      <img src="assets/icons/phone.svg" alt="Phone" class="footer-icon" />
    }

    @for (contact of contacts(); track contact; let i = $index) {
      @if (variety() === "email") {
        <a
          href="mailto:{{ contact }}"
          tabIndex="{0}"
          class="contact-link"
        >
          {{ contact }}
        </a>
      } @else {
        <a
          href="tel:{{ contact }}"
          tabIndex="{0}"
          class="contact-link"
        >
          {{ contact }}
        </a>
      }

      @if (i !== contacts().length - 1) {
        <hr />
      }
    }
  </div>
</div>
