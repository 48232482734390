<input
  #avatarUpload
  type="file"
  [accept]="acceptList"
  (change)="onFileSelected($event)"
  class="avatar-uploader-input"
/>

<div class="avatar-component">
  <a [routerLink]="myAnnouncementLink" class="avatar-component-link">
    @if (profile()?.avatar) {
      <img [src]="profile()?.avatar" alt="" class="avatar" />
    } @else {
      <mat-icon aria-hidden="false" fontIcon="person" class="avatar"></mat-icon>
    }
    {{
      {
        fistName: profile()?.first_name,
        middleName: profile()?.middle_name,
        lastName: profile()?.last_name,
      } | userFullName
    }}
  </a>

  <button
    (click)="avatarUpload.click()"
    [disabled]="loading()"
    class="avatar-component-btn"
  >
    {{ profile()?.avatar ? "Сменить фото" : "Установить фото" }}
  </button>
  <button
    (click)="deleteAvatar()"
    [disabled]="loading()"
    class="avatar-component-btn"
  >
    Удалить фото
  </button>
</div>
