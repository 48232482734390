import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';

export const authHeaderInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);

  if (request.headers.get('skipBearerAuthorization')) {
    return next(request);
  } else {
    if (
      !authService.getTokens().accessToken &&
      !authService.getTokens().refreshToken
    ) {
      console.warn('This request run on server without tokens: ', request.url);
      authService.resetUserData();
    }
  }

  // чтобы исключить Bearer: null
  if (authService.getTokens().accessToken) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.getTokens().accessToken}`,
      },
    });
  }

  return next(request);
};
