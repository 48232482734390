import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, switchMap, throwError } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { formattingApiError } from '@utils/formattingApiError';
import { ROUTE_AUTH } from '@settings/routes';

export const responseErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        error instanceof HttpErrorResponse &&
        !req.url.includes('auth/refresh-token') &&
        error.status === 401
      ) {
        const refreshToken = authService.getTokens().refreshToken;

        if (refreshToken) {
          return authService.tokenUpdate().pipe(
            switchMap((refreshResult) => {
              authService.setTokens(refreshResult);

              return next(
                req.clone({
                  headers: req.headers.set(
                    'Authorization',
                    `Bearer ${authService.getTokens().accessToken}`,
                  ),
                }),
              );
            }),

            catchError((error) => {
              if (error.status === '401') {
                authService.resetUserData();

                router.navigate([ROUTE_AUTH]);
              }

              return throwError(() => formattingApiError(error.error, req.url));
            }),
          );
        }
      }

      if (error.status === 401) {
        authService.resetUserData();

        router.navigate([ROUTE_AUTH]);
      }

      return throwError(() => formattingApiError(error.error, req.url));
    }),
  );
};
