<div class="home-page">
  @if (isMobile()) {
    <app-mobile-header class="header">
      <app-search placeholder="Поиск по объявлениям"></app-search>
      <app-select-location></app-select-location>
    </app-mobile-header>
  }

  <!-- Промо-блок -->
  @if (!isAuth()) {
    <app-promo />
  }

  @if (mainPageCategoriesList()) {
    <!-- Список категорий для мобильной версии -->
    <button
      class="categories-drawer-button"
      (click)="changeShowCategoriesDrawer()"
    >
      Выбрать категории
      <app-sprout-icon width="48px" height="48px"></app-sprout-icon>
    </button>
    <app-categories-drawer
      [isShowCategoriesDrawer]="isShowCategoriesDrawer()"
      (onChange)="changeShowCategoriesDrawer()"
    />

    <!-- Список категорий для десктопной версии -->
    <div class="categories-list">
      <div class="common-categories-block">
        @for (category of mainPageCategoriesList()?.common; track category) {
          <app-category-link [category]="category" class="category-link" />
        }

        <!-- Блок для выравнивания категорий -->
        <div class="empty"></div>
      </div>

      <div class="allotted-categories-block">
        @for (category of mainPageCategoriesList()?.allotted; track category) {
          <app-category-link [category]="category" [allottedCategory]="true" />
        }
      </div>
    </div>
  }

  <div class="tabs">
    <a
      [routerLink]="[homeLink]"
      [queryParams]="{ type_value: 'sell' }"
      [ngClass]="{
        'tabs-link': true,
        left: true,
        active: announcementType === 'sell',
      }"
    >
      Продажа
    </a>
    <a
      [routerLink]="[homeLink]"
      [queryParams]="{ type_value: 'buy' }"
      [ngClass]="{
        'tabs-link': true,
        right: true,
        active: announcementType === 'buy',
      }"
    >
      Покупка
    </a>
  </div>

  <app-data-wrapper
    [isLoading]="isLoading()"
    [isEmpty]="!isLoading() && announcementsList().length < 1"
    emptyDescription="Список объявлений пуст"
  >
    <div class="announcements-list">
      @for (
        announcement of listBeforeAdvertisement();
        track announcement.slug
      ) {
        <app-announcement-card
          [announcement]="announcement"
        ></app-announcement-card>
      }
    </div>
  </app-data-wrapper>

  <div class="advertising">
    <app-advertising-big-banner />
    <app-advertising-small-banner />
  </div>

  <!-- Продолжение списка после блока с рекламой -->
  @if (listAfterAdvertisement().length > 0 && !isLoading()) {
    <div class="announcements-list second">
      @for (announcement of listAfterAdvertisement(); track announcement.slug) {
        <app-announcement-card
          [announcement]="announcement"
        ></app-announcement-card>
      }
    </div>
  }

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</div>
