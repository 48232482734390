@if (error()) {
  <app-info-block
    variety="error"
    [description]="error() || 'Неизвестная ошибка'"
  />
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && myAnnouncements().length < 1"
  emptyDescription="Список активных объявлений пуст"
>
  @for (
    announcement of myAnnouncements();
    let index = $index;
    track announcement.slug
  ) {
    <app-my-announcements-card
      variety="active"
      [announcement]="announcement"
      [isLastCard]="myAnnouncements().length - 1 === index"
    />
  }

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>
